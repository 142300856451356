var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hosting-service-config-container" },
    [
      _vm.loading
        ? _c("ct-centered-spinner", { staticClass: "loading-spinner" }, [
            _c("p", { staticClass: "loading-text" }, [
              _vm._v("\n      " + _vm._s(_vm.text.savingMessage) + "\n    "),
            ]),
          ])
        : _vm._e(),
      _vm.hostingTransferInfo || _vm.standaloneHostingInfo
        ? _c(
            "div",
            { staticClass: "hosting-transfer-info-section" },
            [
              _vm.hostingTransferInfo
                ? _c("hosting-transfer-status", {
                    attrs: {
                      "pending-hosting-info": _vm.hostingTransferInfo,
                      bus: _vm.bus,
                    },
                  })
                : _vm._e(),
              _vm.standaloneHostingInfo
                ? _c("standalone-hosting-status", {
                    attrs: {
                      "pending-hosting-info": _vm.standaloneHostingInfo,
                      bus: _vm.bus,
                    },
                  })
                : _vm._e(),
              _vm.showPrimaryNavButton
                ? _c(
                    "div",
                    {
                      staticClass:
                        "slide-viewer-button-row primary-button-container",
                    },
                    [
                      _vm.hostingTransferInfo || _vm.standaloneHostingVerified
                        ? _c("primary-button", {
                            staticClass: "button-padding",
                            attrs: {
                              "button-text": _vm.navigationButtonText,
                              disabled: _vm.loading,
                              "aria-label":
                                _vm.navigationButtonText + " button",
                            },
                            on: {
                              onclick: function ($event) {
                                return _vm.navigateFromHostingTransferInfo()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("primary-button", {
                staticClass: "website-page-nav-button button-padding",
                attrs: {
                  "button-text": _vm.text.navButton.websitePagePrompt,
                  disabled: false,
                  "aria-label":
                    _vm.text.navButton.websitePagePrompt + " button",
                },
                on: {
                  onclick: function ($event) {
                    return _vm.navigateToNextPage()
                  },
                },
              }),
              _c("transfer-service-section", {
                attrs: { "service-type": "hosting" },
                on: { "show-transfer-modal": _vm.showTransferModal },
              }),
              _c("hosting-transfer-modal", {
                attrs: { bus: _vm.bus },
                model: {
                  value: _vm.showHostingTransferModal,
                  callback: function ($$v) {
                    _vm.showHostingTransferModal = $$v
                  },
                  expression: "showHostingTransferModal",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }